
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {

      Blog: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBlog: null,
      BlogSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addBlog() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addBlog",
        inputs: [
          {
            "model": "image",
            "type": "file",
            "label": "image"
          },
          {
            "model": "descr",
            "type": "ckeditor",
            "label": "descr"
          },

        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.do("blog", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editBlog(app) {
      var data = {
        title: "popups.blog",
        inputs: [
          {
            "model": "image",
            "type": "file",
            "label": "image",
            "value": app.image
          },
          {
            "model": "descr",
            "type": "ckeditor",
            "label": app.descr
          },

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("blog", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateBlog(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            "model": "image",
            "type": "text",
            "label": "image"
          },
          {
            "model": "descr",
            "type": "text",
            "label": app.descr
          },

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("blog", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.BlogSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("blog/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Blog = res.data;
          console.log("######## files", this.Blog);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.BlogSearchMode = false;
      this.get(this.page);
    },

    getBlog() {
      this.http.get("blog").then((res) => {
        this.Blog = res.data;
      });
    },
    deleteBlog(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("blog", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("blog/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Blog = res.data;

        });
    },
  },
  created() {
    this.get(1);
    this.getBlog();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.blog.text')" />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input @keyup="search()" type="text" class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
              style="background-color: #2a3042 !important" />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button class="btn-close text-light" v-if="BlogSearchMode" @click="cancelappsearchMode()"></button>
        <button type="button" @click="addBlog()" class="btn btn-light float-end mb-4">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"  class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope='col'>{{ $t('blog.id') }}
          </th>
          <th scope='col'>{{ $t('blog.image') }}
          </th>
          <th scope='col'>{{ $t('blog.descr') }}
          </th>
          <th scope='col'>{{ $t('blog.created') }}
          </th>
          <th scope='col'>{{ $t('blog.updated') }}
          </th>
          <th scope='col'>{{ $t('blog.operations') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Blog" :key="app" class="text-center">
          <td>{{ index + 1 }}</td>
          <td><img :src="$store.state?.auth?.mediaUrl+app.image.split('public')[1] "  style="width: 64px;height: 64px;" alt=""></td>
          <td>{{ app.descr }}</td>
          <td>{{ app.created.split('T')[0] }}</td>
          <td>{{ app.updated.split('T')[0] }}</td>

          <td class="d-flex">
            <a class="btn btn-primary mx-1" @click="editBlog(app)" href="javascript: void(0);" role="button">{{
              $t("popups.edit") }}</a>
            <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteBlog(app)">{{
              $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




